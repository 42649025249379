import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { enrollChallenge } from "../../helper/service";
import SuccessPopup from "../Setting/SuccessPopup";

function ChallengeEnrollBtn({ challengeId }) {
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const queryClient = useQueryClient();
  const enrollChallngeMutation = useMutation({
    mutationKey: ["enrollChallenge", challengeId],
    mutationFn: () => {
      return enrollChallenge(challengeId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getRecentChallenges"]);
      setShowSuccessPopup(true);
    },
  });

  if (!challengeId) return null;

  const handleEnroll = () => {
    const confirm = window.confirm("Are you sure you want to enroll?");
    if (!confirm) return;
    enrollChallngeMutation.mutate();
  };

  return (
    <>
      {showSuccessPopup ? (
        <SuccessPopup message="You have been enrolled succesfully and added to social wall for the course/challenge." />
      ) : (
        <></>
      )}
      <button onClick={handleEnroll}>
        {enrollChallngeMutation.isPending ? (
          <span>Enrolling...</span>
        ) : (
          <span>Enroll</span>
        )}
      </button>
    </>
  );
}

export default ChallengeEnrollBtn;
