import React, { useEffect,useState } from 'react';
import Header from './Header';
import Avatar from '../../images/placeholder.jpg';
import { useNotification } from '../../contexts/NotificationContext';
import { commonUtil } from "../../helper/CommonUtils"
import ConfirmationModal from '../Setting/ConfirmationModal';
import {faTrashCan, faSquareCheck, faArrowUpRightFromSquare} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const AllNotifications = () => {
    const brandColor=localStorage.getItem('subadminBrandColor');
    const BASEURL = commonUtil.BASEURL();
    const navigate = useNavigate();
    const {removeNotification,deleteNotification}=useNotification();
    const { notifications } = useNotification();
    const [modalMessage,setModalMessage]=useState('');
    const [showWarningModal,setShowWarningModal]=useState(false);
    const [checkedNotification,setCheckedNotification]=useState([]);
    const moveToPage = (path) =>{
        navigate(path);
    }    
    
    const handleClick = (id) => {
        removeNotification(id);
    };

    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
    const uniqueNotification = Array.from(new Set(notifications.map(obj => obj._id)))
        .map(id => notifications.find(obj => obj._id === id))
        .filter(notification => new Date(notification.createdAt) >= sixMonthsAgo);;
    
    const totalNotifications = uniqueNotification?.length ?? 0;
    const resetStates=()=>{
        setShowWarningModal(false);
        setModalMessage('');
        setCheckedNotification([]);
    }
    const saveChecked = (id) => {
        setCheckedNotification((prevState) => {
            if (prevState.includes(id)) {
                return prevState.filter(item => item !== id);
            } else {
                return [...prevState, id];
            }
        });
    };
    const handleSelectAll = () => {
        if (checkedNotification.length === uniqueNotification.length) {
            setCheckedNotification([]);
        } else {
            const allIds = uniqueNotification.map(item => item._id);
            setCheckedNotification(allIds);
        }
    };
    const handleDelete = (e,id) => {
        if(id){
            saveChecked(id);
        }
        const message=`Are you sure you want to delete ${checkedNotification.length>0?checkedNotification.length:''} ${checkedNotification.length > 1 ?"notifications":'notification'}?`
        setShowWarningModal(true);
        setModalMessage(message);
    }
    const isAllSelected = checkedNotification.length === uniqueNotification.length;
    return (
        <>
            <Header />
            {showWarningModal && <ConfirmationModal 
                type="delete" 
                title="Delete Confirmation!" 
                message={modalMessage} 
                onClose={resetStates} 
                onConfirm={()=>{
                    if(checkedNotification.length > 0){
                        deleteNotification(checkedNotification,isAllSelected);
                    }
                    resetStates();
                }}
            />}
            <div className="content-wrapper admin-social-wall">
                <h3 className='mt-3 ml-3'>All Notifications</h3>
                <div className='d-flex justify-content-center'>
                    <div className='box-header py-2 pl-md-0 w-75'>
                        <label className='h7 pl-0 pl-md-2 d-flex justify-content-between gap-2 mb-0 align-items-center'>
                        <FontAwesomeIcon
                            icon={faSquareCheck}
                            onClick={handleSelectAll}
                            style={isAllSelected?{color:`${brandColor}`,border:'.5px solid white'}:''}
                            className='check'
                        />
                        <span hidden={checkedNotification?.length > 0 } className='text-white'>
                            Select all
                        </span>
                        <span hidden={checkedNotification?.length === 0 } className='text-white'>
                        {checkedNotification?.length} slected
                        </span>
                        </label>
                        {checkedNotification.length > 0 && 
                        (<div className='pl-2 d-flex'>
                            <button className='btn btn-sm' onClick={handleDelete}>
                            <FontAwesomeIcon icon={faTrashCan} className='mr-1'/>
                            Delete
                            </button>
                        </div>)}
                    </div>
                </div>
                {uniqueNotification.length > 0 ? (uniqueNotification.map((notification) => {
                    const createdAt = new Date(notification.createdAt);
                    const currentTime = new Date();

                    // Calculate the time difference in milliseconds
                    const timeDifference = currentTime - createdAt;

                const minutesDifference = Math.floor(timeDifference / (1000 * 60));
                const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
                const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                const weeksDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 7));
                
                let  hour, minute, day, weeks;
                
                if (!hoursDifference && minutesDifference > 0) {
                    minute = minutesDifference
                }
                else if (!daysDifference && hoursDifference > 0) {
                    hour = hoursDifference;
                }
                else if (!weeksDifference && daysDifference > 0) {
                    day = daysDifference
                }
                else if(weeksDifference){
                    weeks=weeksDifference;
                }
                    return (
                        <>
                            <div className='w-75 d-flex justify-content-center mx-auto border-bottom pb-2 align-items-center notification-list' key={notification._id}>
                            <FontAwesomeIcon 
                                icon={faSquareCheck}
                                value={notification._id}
                                onClick={() => saveChecked(notification._id)}
                                style={checkedNotification.includes(notification._id)?{color:`${brandColor}`}:''}
                                className='check ml-2'
                            />
                                <div className="dropdown-item" style={{fontSize:"14px"}} onClick={(e) => e.preventDefault()}>
                                    <div className="d-flex align-items-center">
                                        <div className="all-notification-container  w-100 d-flex flex-column" style={{position:"relative"}}>
                                            <div className="all-notification-content">
                                                <div className='d-flex justfiy-content-start'>
                                                    <span>
                                                        <strong className={notification.status==='Read'?'text-muted':''}>{notification.webContent.title}</strong>
                                                    </span>
                                                </div>

                                                <span className='time'>
                                                    <small className="text-muted text-sm">
                                                    {weeks>0?weeks+" week ago": day>0? day+" day ago": hour > 0 ? hour + " hour ago" : minute > 0 ? minute+ " min ago":"Just now"}
                                                    </small>
                                                </span>
                                            </div>
                                            
                                            <div className="mark-as-read-for-all" >
                                                {notification.status !== 'Read' &&
                                                (
                                                    <Tooltip title="Mark as Read" placement="bottom">
                                                    <div className="action-icon" onClick={()=>handleClick(notification._id)}>
                                                        <FontAwesomeIcon icon={faSquareCheck} width={22} height={22}/>   
                                                    </div>
                                                    </Tooltip>
                                                )}
                                                {notification.webContent.link && (
                                                    <Tooltip title="View" placement="bottom">
                                                        <div className="action-icon" onClick={()=>moveToPage(notification.webContent.link)}>
                                                            <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                                                        </div>
                                                    </Tooltip>
                                                )}
                                                <Tooltip title="Delete" placement="bottom">
                                                    <div className="action-icon" onClick={(e)=>handleDelete(e,notification._id)}>
                                                        <FontAwesomeIcon icon={faTrashCan} width={22} height={22} className=''/>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            <div style={{ fontSize: "0.85rem" }}>{notification.webContent.description}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })):<div className='d-flex align-items-center justify-content-center my-2'>No New Notification to Show</div>}
            </div>
        </>
    )
}

export default AllNotifications