import React, { Component } from 'react';
import Group68026 from '../../images/admin/Group 68026.svg';
import Group68025 from '../../images/admin/Group 68025.svg';
import { Modal, Button } from "react-bootstrap";
import close from '../../images/dashboard/modal-close.svg';
import submit from '../../images/admin/submit.svg';
import $ from 'jquery';
import ReactPlayer from 'react-player';
import placeholder from '../../images/placeholder.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import defaultImg from "../../images/avatar.jpeg";


import { coursecommentlistuser, saveCoursecommentuser, saveCoursecomment, coursecommentlist, getLessonById, challengelivelesson, challengedraftlesson, challengeschedulelesson, challengeinsidelesson } from '../../helper/service'

export default class TopSearchResultComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            live: '',
            draft: '',
            schedule: ''
        }
    }


    openModal = () => { this.setState({ isOpen: true }); console.log('called popup') };
    closeModal = () => this.setState({ isOpen: false });


    listData() {
        console.log(this.props, 'props')
        const userId = localStorage.getItem('user_id');
        var lessonId = this.props.chlessonid;

        if (this.props.type === 'details') {
            console.log("inside if")
            challengeinsidelesson(lessonId).then(data => {
                const datas = {
                    res: data.result
                }
                console.log(datas, "data_lesson")
                this.iterateFolders(datas, 'live');
            })
            challengeschedulelesson(lessonId).then((data) => {
                const res = {
                    message: ' Exist',
                    status: 200,
                    res: data.result,
                }
                this.iterateFolders(res, 'schedule')
            })
        } else {
            console.log("inside else")
            challengedraftlesson(userId).then(data => {
                const datas = {
                    res: data.result
                }
                console.log(datas, "data_lesson")
                this.iterateFolders(datas, 'live');
            })
        }
    }

    async componentDidMount() {
        this.listData();
    }


    iterateFolders = async (datas, types) => {
        if (!datas.res.hasOwnProperty('status')) {
            var i = 0;
            var folders = datas.res.map(function (subs) {
                let subsSchedule = subs?.schedule;
                let subsCreatedDate = '';
                let formattedDateTime = '';
                if (subsSchedule) {
                    var originalDate = new Date(subsSchedule);
                    var selectedTimeZone = localStorage.getItem('websiteTimeZone');
                    if (selectedTimeZone) { // Change this to your desired timezone
                        subsCreatedDate = originalDate.toLocaleString('en-US', { timeZone: selectedTimeZone });

                        var currentDateTime = new Date();
                        formattedDateTime = currentDateTime.toLocaleString('en-US', { timeZone: selectedTimeZone });
                    }
                }
                if (!subs.hideForMember) {
                    if (subsCreatedDate < formattedDateTime || !subsSchedule) {
                        i++;
                        return (
                            <tr key={subs._id}>
                                <td>
                                    <h3 class="table-title">{i}</h3>
                                </td>
                                <td>
                                    <h3 class="table-title">{subs.title}</h3>
                                </td>
                                <td>
                                    {subs.description}
                                </td>
                                <td>
                                    <button class="btn btn-live" onClick={this.lessonDetails.bind(this, subs._id)}>Preview</button>
                                </td>
                            </tr>
                        )
                    }
                }
            }, this)
            this.props.handleLessonCount(i);
        } else {
            var folders = "No records found.";
        }
        if (types == "live") { this.setState({ live: folders }) }
        if (types == "draft") { this.setState({ draft: folders }) }
        if (types == "schedule") { this.setState({ schedule: folders }) }
    }

    lessonDetails(lessonId) {
        getLessonById(lessonId).then(data => {
            if (data) {
                console.log(data, "preview_data")
                if (data.result) {
                    var files = data.result.eventFile[0]['fileUrl'];
                    var fileName = files?.substring(files.lastIndexOf("/") + 1, files.lastIndexOf("."));

                    var fileExt = files?.substring(files.lastIndexOf('.') + 1);

                    var types = "";
                    if (fileExt == "png" || fileExt == "jpeg" || fileExt == "jpg") {
                        types = "image";
                    }
                    if (fileExt == "mp4" || fileExt == "mov") {
                        types = "video";
                    }
                    if (fileExt == "mp3") {
                        types = "audio";
                    }
                    this.coursecommentlist(lessonId);



                    const resource = data.result.newResource.map(function (
                        resource,
                    ) {
                        var filename = (resource.resourceUrl && resource.resourceUrl.split('/').pop()) || '';

                        return (
                            <div className="resourcesLists">
                                <a href={resource.resourceUrl} target="_blank">
                                    {filename}
                                </a>
                            </div>
                        )

                    },
                        this)
                    this.setState({
                        title: data.result.title,
                        description: data.result.description,
                        fileName: fileName,
                        types: types,
                        files: files,
                        currentLesson: lessonId,
                        currentCourse: data.result.challengeId._id,
                        resource: resource
                    })
                }
                this.openModal();
            }
        }, this)
    }

    async savevideocomment() {
        if (this.state.comment) {
            this.setState({ is_loader: true })
            let user_id = localStorage.getItem('user_id');
            console.log(user_id, "memberid")
            const commentData = {
                "lessonId": this.state.currentLesson,
                "courseId": this.state.currentCourse,
                "user_id": user_id,
                "comment": this.state.comment
            }
            await saveCoursecommentuser(commentData);
            this.coursecommentlist(this.state.currentLesson);
            this.setState({ is_loader: false, comment: '' })
        }
    }




    iteratecomments(datas) {
        let user_id = localStorage.getItem('user_id');
        const comment = datas.commentByUserId.map(function (vid) {
            var imgs = "";
            var key = "";
            if (vid.userId) {
                key = vid.userId;
            } else {
                key = vid.subadminId;
            }
            if (key && key.company && key.company.length > 0 && key.company[0].logo) {
                imgs = key.company[0].logo;
            } else if (key.image) {
                imgs = key.image;
            } else {
                imgs = placeholder;
            }

            return (
                <div className="media">
                    <img src={imgs} className="user-img img-fluid mr-3" />
                    <div className="media-body">
                        <h5 className="mt-0 messanger-name">
                            {key && key.firstName} {key && key.lastName}
                        </h5>
                        <p className="messanger-text">
                            {vid.comment}
                        </p>
                    </div>
                </div>
            )
        }, this)
        if (datas.commentByUserId.length > 0) {
            this.setState({ videocomment: comment })
        } else {
            this.setState({ videocomment: 'No records found.' })
        }

    }



    async coursecommentlist(idd) {
        coursecommentlistuser(idd).then(data => {
            this.iteratecomments(data.result);
        })
    }


    enterPressed(event) {
        var code = event.keyCode || event.which;
        if (code === 13) {
            this.savevideocomment();
        }
    }



    onChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        this.setState({ [name]: value });
    }



    render() {
        return (
            <section class="courses-list">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-12">
                            <ul class="nav nav-tabs" id="coursesTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" id="liveLessons-tab" data-toggle="tab" href="#liveLessons" role="tab" aria-controls="liveLessons" aria-selected="true">Latest Lessons</a>
                                </li>

                                {/* <li class="nav-item" role="presentation">   
                                    <a class="nav-link" id="scheduledLessons-tab" data-toggle="tab" href="#scheduledLessons" role="tab" aria-controls="scheduledLessons" aria-selected="false">Scheduled Lessons</a>
                                </li> */}

                                {/* <li class="nav-item" role="presentation">
                                    <a class="nav-link" id="draftLessons-tab" data-toggle="tab" href="#draftLessons" role="tab" aria-controls="draftLessons" aria-selected="false">Draft Lessons</a>
                                </li> */}
                            </ul>
                            <div class="tab-content" id="coursesTabContent">
                                <div class="tab-pane fade show active" id="liveLessons" role="tabpanel" aria-labelledby="liveLessons-tab">
                                    <div class="table-responsive">
                                        <table class="table table-hover">
                                            <tbody>
                                                {this.state.live}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="scheduledLessons" role="tabpanel" aria-labelledby="scheduledLessons-tab">
                                    <div class="table-responsive">
                                        <table class="table table-hover">
                                            <tbody>
                                                {this.state.schedule}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="draftLessons" role="tabpanel" aria-labelledby="draftLessons-tab">
                                    <div class="table-responsive">
                                        <table class="table table-hover">
                                            <tbody>
                                                {this.state.draft}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>




                <Modal className="audioPreviewModal" show={this.state.isOpen} onHide={this.closeModal} backdrop="static">
                    <Modal.Header className="modal-header border-0 pb-0 align-items-center">
                        <h5 class="modal-title pl-0">Preview Lesson</h5>
                        <button onClick={this.closeModal} type="button" className="close ml-5" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><img src={close} /></span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center mb-2">

                            {
                                (this.state.types == "image" ?
                                    <img src={this.state.files} class="img-fluid mx-auto d-block mb-2" height="180" />
                                    : '')
                            }

                            {
                                (this.state.types == "video" ?
                                    <ReactPlayer config={{ file: { attributes: { controlsList: 'nodownload' } } }} url={this.state.files} controls={true} playing={false} width="100%" height="353px" />
                                    : '')
                            }

                            {
                                (this.state.types == "audio" ?
                                    <ReactPlayer url={this.state.files} controls={true} playing={false} width="100%" height="50px" />
                                    : '')
                            }


                            {/* <label className='text-break'>{this.state.fileName}</label> */}
                        </div>
                        <h5 className='modal-title mr-0'>{this.state.title}</h5>
                        <p className='text-break'>{this.state.description}</p>
                        <h5 className="modal-title mr-0 mb-2">Resources</h5>
                        {console.log(this.state.resource, "challenges_resourcce")}
                        <div className="scroll">
                            <div className='resources mb-3'>
                                {(this.state.resource) != '' ? (this.state.resource) : 'Resource not available.'}
                            </div>
                        </div>
                        <div className="comments">

                            <div className="form-row mb-3 align-items-center">
                                <div className="col-10">
                                    <input
                                        type="text"
                                        className="form-control leave-comment"
                                        placeholder="Leave a comment"
                                        name="comment"
                                        onChange={this.onChange}
                                        onKeyPress={this.enterPressed.bind(this)}
                                        value={this.state.comment}
                                    />
                                </div>
                                <div className="col-2">
                                    <img
                                        src={submit}
                                        className="img-fluid comment-img"
                                        width={56}
                                        height={38}
                                        onClick={this.savevideocomment.bind(this)}
                                    />
                                </div>
                            </div>

                            <h4 className="title">
                                Comments
                                {/* <div className="custom-control custom-switch float-right">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="commentSwitch"
                            defaultChecked=""
                        />
                        <label className="custom-control-label" htmlFor="commentSwitch" />
                        </div> */}
                            </h4>
                            <div className="messages-list">
                                {this.state.videocomment}
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>


            </section>
        )
    }
}