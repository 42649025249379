import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Header from "../common/Header";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import CheckoutForm from "./CheckoutForm";
import Loader from "../Loader";

import {
  createMemberPayment,
  createMemberCoursePayment,
} from "../../helper/service";
import { set } from "date-fns";

const options1 = {
  mode: "payment",
  amount: 1099,
  currency: "aud",
  // Fully customizable with appearance API.
  appearance: {
    theme: "stripe",
    variables: {
      colorPrimary: localStorage.getItem("subadminBrandColor"),
    },
  },
};

const Checkout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    subscriptionId,
    subscriptionFrequency,
    flow,
    courseId,
    courseFrequency,
  } = useParams();
  const [showPayment, setShowPayment] = useState(false);
  const [stripeOptions, setStripeOptions] = useState({});
  const [stripePromise, setstripePromise] = useState(null);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (
      (!subscriptionId ||
        subscriptionId === undefined ||
        !subscriptionFrequency ||
        subscriptionFrequency === undefined) &&
      (!courseId || courseId === undefined)
    )
      return;

    if (subscriptionId) {
      createMemberPayment(subscriptionId, subscriptionFrequency)
        .then((data) => {
          console.log(data, "strip result");
          if (data && data.success) {
            const ops = {
              clientSecret: data.clientSecret,
            };

            setStripeOptions(ops);
            setstripePromise(loadStripe(data.publishableKey));
            setShowPayment(true);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log("create member payment error: " + err);
          setHasError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (courseId) {
      createMemberCoursePayment(courseId, courseFrequency)
        .then((data) => {
          console.log(data, "strip result");
          if (data && data.success) {
            const ops = {
              clientSecret: data.clientSecret,
            };

            setStripeOptions(ops);
            setstripePromise(loadStripe(data.publishableKey));
            setShowPayment(true);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log("create member payment error: " + err);
          setHasError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  return (
    <>
      {flow && flow === "register" ? <></> : <Header />}
      {isLoading ? <Loader /> : <></>}
      {!hasError ? (
        <div className="content-wrapper admin-social-wall">
          <h3 style={{ margin: "1em 0em 0.5em 0.5em" }}>Checkout</h3>
          <p style={{ marginLeft: "1em" }}>
            {subscriptionId
              ? "YOU ARE NOW PAYING TO SUBSCRIBE TO A PAID PLAN"
              : "YOU ARE NOW PAYING TO ENROLL IN A PAID COURSE"}
          </p>
          <Container>
            {showPayment ? (
              <Elements stripe={stripePromise} options={stripeOptions}>
                {subscriptionId ? (
                  <CheckoutForm
                    subscriptionId={subscriptionId}
                    subscriptionFrequency={subscriptionFrequency}
                  />
                ) : (
                  <CheckoutForm
                    courseId={courseId}
                    courseFrequency={courseFrequency}
                  />
                )}
              </Elements>
            ) : (
              <></>
            )}
          </Container>
        </div>
      ) : (
        <div className="content-wrapper admin-social-wall">
          <h3 style={{ margin: "1em 0em 0.5em 0.5em" }}>Checkout</h3>
          <p style={{ marginLeft: "1em" }}>
            Error occured while processing payment. Please try again later or
            try contacting your instructor.
          </p>
          <button
            className="btn btn-primary"
            style={{ backgroundColor: localStorage.getItem("subadminBrandColor"), border: "none", marginLeft: "1em" }}
            onClick={() => window.history.back()}
          >
            Go Back
          </button>
        </div>
      )}
    </>
  );
};

export default Checkout;
