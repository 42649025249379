import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import countriesList from "../country.json";
import Loader from "../Loader";
import ConfirmationModal from "../Setting/ConfirmationModal";

import {
  register,
  getMembershipLevel,
  subscribefree,
  createMemberPayment,
  createMemberCoursePayment,
  subadminInfo,
  getSaleSelectedCourse,
  getMemberData,
  login,
} from "../../helper/service";
import { commonUtil } from "../../helper/CommonUtils";

const TaxRate = 0.1;

export default function CheckoutRegistration(props) {
  const {
    subscriptionId,
    subscriptionFrequency,
    subadminId,
    courseId,
    courseFrequency,
  } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    postalCode: "",
    streetAddress: "",
    country: "Australia",
  });
  const [formErrors, setFormErrors] = useState({});
  const [memberData, setMemberData] = useState({});
  const [brandColor, setBrandColor] = useState("");
  const [plan, setPlan] = useState({});
  const [isMonthly, setIsMonthly] = useState(true);
  const [orderPrice, setOrderPrice] = useState(0.0);
  const [orderTax, setOrderTax] = useState(0.0);
  const [orderTotal, setOrderTotal] = useState(0.0);
  const [hasError, setHasError] = useState(false);
  const [disableNextBtn, setDisableNextBtn] = useState(false);
  const [showAuthenticate, setShowAuthenticate] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [disableEmail, setDisableEmail] = useState(false);
  const [disablePassword, setDisablePassword] = useState(false);
  const [message, setMessage] = useState("");
  const [showWarningModal, setWarningModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [warningModalTitle, setWarningModalTitle] = useState("");

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    var isMonthlyPayment = props.subscriptionFrequency === "monthly";
    setIsMonthly(isMonthlyPayment);

    const data = await subadminInfo(subadminId);
    if (data) {
      setBrandColor(data.result.validUser.brandColor);
      localStorage.setItem(
        "subadminBrandColor",
        data.result.validUser.brandColor
      );
    }

    let subsId = subscriptionId;
    if (subsId) {
      getMembershipLevel(subsId).then((subs) => {
        if (subs?.result?.validSubs) {
          var subscription = subs?.result?.validSubs;
          if (subscription) {
            setPlan(subscription);

            if (subscription.substype === "Free") {
              props.updateHeadingHandler(
                "YOU ARE NOW SUBSCRIBING TO A FREE PLAN"
              );
            }

            var orderMonthlyPrice =
              Math.round(subscription.amountMonthly * 100) / 100;
            var orderYearlyPrice =
              Math.round(subscription.amountYearly * 100) / 100;

            var originalPrice = isMonthlyPayment
              ? orderMonthlyPrice
              : orderYearlyPrice;
            var currentPrice = originalPrice / 1.1;
            var tax = currentPrice * TaxRate;

            setOrderPrice(currentPrice.toFixed(2));
            setOrderTax(tax.toFixed(2));
            setOrderTotal(originalPrice.toFixed(2));
          }
        }
      });
    } else if (courseId) {
      getSaleSelectedCourse(courseId).then((course) => {
        let selectedCourse = course.result[0];
        let singleCourse = {
          tier: selectedCourse.title,
          description: selectedCourse.shortDescription,
        };
        var orderPrice = Math.round(selectedCourse.amount * 100) / 100;
        var currentPrice = orderPrice / 1.1;
        var tax = currentPrice * TaxRate;
        setPlan(singleCourse);
        setOrderPrice(currentPrice.toFixed(2));
        setOrderTax(tax.toFixed(2));
        setOrderTotal(orderPrice.toFixed(2));
      });
    }
  };

  const {
    firstName,
    lastName,
    email,
    password,
    phone,
    postalCode,
    streetAddress,
    country,
  } = formData;

  useEffect(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(email)) {
      getMemberData(email).then((data) => {
        let user = data.result.validUser;
        if (user && user.subadminsId) {
          setMemberData(user);
          console.log(user, "user");
          if (subscriptionId) {
            let partIndex = user.subadminsId.findIndex(
              (subadmin) => subadmin.subadminId._id === subadminId
            );
            let checkSubs = user.subscriptionId[partIndex].subsId._id;
            let checkSubsStatus = user.subscriptionId[partIndex].status;
            if (subscriptionId === checkSubs && checkSubsStatus === "Active") {
              setWarningModal(true);
              setMessage("You already have this subscription. Go back and buy other subscription.");
              setModalType("warning");
              setWarningModalTitle("Warning!");
              setShowAuthenticate(false);
              setDisableNextBtn(true);
            } else {
              setWarningModal(true);
              setMessage("Looks like you are already a member with Wisdome, please enter password to continue.");
              setModalType("info");
              setWarningModalTitle("FYI");
              setShowAuthenticate(true);
              setDisableNextBtn(true);
            }
  
          } else if (courseId) {
            const hasPurchasedCourse = user.userCourses.some(course => course.purchaseCourseId === courseId);
  
            if (hasPurchasedCourse) {
              setWarningModal(true);
              setMessage("You have already purchased this course.");
              setModalType("info");
              setWarningModalTitle("FYI");
              setShowAuthenticate(false);
              setDisableNextBtn(true);
            } 
  
          } else {
            setWarningModal(true);
            setMessage("Looks like you are already a member with Wisdome, please enter password to continue.");
            setModalType("info");
            setWarningModalTitle("FYI");
            setShowAuthenticate(true);
            setDisableNextBtn(true);
          }
        }
      });
    }
  }, [email]);  

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    let errors = {};

    if (!firstName) {
      errors.firstName = "*Required";
    }

    if (!lastName) {
      errors.lastName = "*Required";
    }

    if (!password) {
      errors.password = "*Required";
    }
    if (password?.length < 6) {
      errors.password = "Password should be minimum 6 characters long";
    }

    if (!email) {
      errors.email = "*Required";
    } else {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(email)) {
        errors.email = "*Required";
      }
    }

    if (!country) {
      //errors.country = '*Required';
    }
    if (!streetAddress) {
      errors.streetAddress = "*Required";
    }
    if (!postalCode) {
      errors.postalCode = "*Required";
    }
    if (!phone) {
      errors.phone = "*Required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const {
      firstName,
      lastName,
      email,
      password,
      phone,
      country,
      streetAddress,
      postalCode,
    } = formData;

    const dataIsValid = validate();

    if (dataIsValid) {
      localStorage.setItem("subadminId", subadminId);
      if (subscriptionId) {
        var registrationRequest = {
          firstName,
          lastName,
          email,
          password,
          mobile: phone,
          subsId: subscriptionId,
          subsPlan: subscriptionFrequency,
          subadmin: subadminId,
        };

        setIsLoading(true);
        if (!isAuthenticated) {
          register(registrationRequest).then((memberData) => {
            if (memberData.status === 200) {
              subscriptionPayment(registrationRequest);
            }
          });
        } else {
          subscriptionPayment(registrationRequest);
        }
        setIsLoading(false);
      } else if (courseId) {
        var registrationRequest = {
          firstName,
          lastName,
          email,
          password,
          mobile: phone,
          courseId: courseId,
          coursePlan: "onetime",
          subadmin: subadminId,
        };

        setIsLoading(true);
        if (!isAuthenticated) {
          register(registrationRequest).then((memberData) => {
            if (memberData.status === 200) {
              coursePayment(registrationRequest);
            }
          });
        } else {
          coursePayment(registrationRequest);
        }
      } 
      setIsLoading(false);
    }
  };
  
  const subscriptionPayment = async (registrationRequest) => {
    if (plan.substype !== "Free") {
      createMemberPayment(subscriptionId, subscriptionFrequency)
        .then((data) => {
          if (data && data.success) {
            props.startPaymentHandler(data, registrationRequest);
          }
        })
        .catch((err) => {
          console.log("create member payment error: " + err);
          setHasError(true);
          props.errorHandler(
            "Error occured while processing payment. Please try again later or try contacting your instructor."
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      let data = {
        subadminId: subadminId,
        subscriptionId: {
          subsId: subscriptionId,
        },
      };
      subscribefree(data).then((data) => {
        setWarningModal(true);
        setMessage("You have selected free membership.");
        setModalType("info");
        setWarningModalTitle("FYI");
      });
    }
  };
  const coursePayment = async (registrationRequest) => {
    createMemberCoursePayment(courseId, "onetime")
      .then((data) => {
        if (data && data.success) {
          props.startPaymentHandler(data, registrationRequest);
        }
      })
      .catch((err) => {
        console.log("create member payment error: " + err);
        setHasError(true);
        props.errorHandler(
          "Error occured while processing payment. Please try again later or try contacting your instructor."
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const checkAuthenication = () => {
    let data = {
      email: email,
      password: password,
    };
    login(data).then((res) => {
      if (!res.success) {
        setWarningModal(true);
        setMessage("Invalid credentionals.");
        setModalType("warning");
        setWarningModalTitle("ERROR!!!");
        
      } else {
        setDisableNextBtn(false);
        setIsAuthenticated(true);
        setDisableEmail(true);
        setDisablePassword(true);
        setFormData({
          firstName: res.user.firstName,
          lastName: res.user.lastName,
          phone: res.user.mobile,
          email: res.user.email,
          password: data.password,
        });
      }
    });
  };

  const headingStyle = {
    color: brandColor,
    fontWeight: "bold",
    fonnSize: "1.5rem",
  };

  const inputBoxStyle = {
    backgroundColor: "white",
    borderColor: "#D2D2D2",
    height: "2.5em",
  };

  const priceSectionStyle = {
    fontWeight: "600",
    fonnSize: "1.5rem",
  };
  const errorMessageStyle = {
    color: "red",
    fontSize: "0.7rem",
  };
  const lineStyle = {
    width: "55%",
    marginLeft: "5%",
    marginRight: "20%",
    marginTop: ".3em",
    marginBottom: ".3em",
    height: "1px",
    backgroundColor: "#D2D2D2",
  };

  return (
    <>
      {isLoading ? <Loader /> : <></>}
      {showWarningModal && (
        <ConfirmationModal
          type={modalType}
          title={warningModalTitle}
          message={message}
          onClose={() => {
            setWarningModal(false);
            setMessage("");
            setModalType("");
            setWarningModalTitle("");
            if (modalType === "info" && message === "You have selected free membership.") 
            {
              commonUtil.redirect("/account-details");
            }
            else if(modalType === "warning" && message === "You already have this subscription. Go back and buy other subscription.")
            {
              window.location.reload();
            }
          }}
        />
      )}
      {!hasError && (
        <Form>
          <Row>
            <Col>
              <Form.Label style={headingStyle}>ACCOUNT DETAILS</Form.Label>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  required
                  disabled={disableEmail}
                  style={inputBoxStyle}
                />
                <span style={errorMessageStyle}>{formErrors.email}</span>
              </Form.Group>
              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                  placeholder="Create your password"
                  required
                  disabled={disablePassword}
                  style={inputBoxStyle}
                />
                <span style={errorMessageStyle}>{formErrors.password}</span>
              </Form.Group>
              {showAuthenticate && (
                <Form.Group>
                  <Button
                    style={{ backgroundColor: brandColor, border: "none" }}
                    onClick={checkAuthenication}
                    type="submit"
                    disabled={isAuthenticated}
                  >
                    Authenticate
                  </Button>
                  {isAuthenticated && (
                    <span style={{ marginLeft: "5px" }}>
                      Authenticated successfully
                    </span>
                  )}
                </Form.Group>
              )}
              <Form.Label style={headingStyle}>
                BILLING and CONTACT DETAILS
              </Form.Label>
              <Form.Group>
                <span style={errorMessageStyle}>*</span>
                <span style={{ fontSize: "0.7rem" }}> required fields</span>
              </Form.Group>

              <Form.Group>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={firstName}
                  onChange={handleChange}
                  placeholder="Enter your first name"
                  required
                  style={inputBoxStyle}
                />
                <span style={errorMessageStyle}>{formErrors.firstName}</span>
              </Form.Group>
              <Form.Group>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={lastName}
                  onChange={handleChange}
                  placeholder="Enter your last name"
                  required
                  style={inputBoxStyle}
                />
                <span style={errorMessageStyle}>{formErrors.lastName}</span>
              </Form.Group>
              <Form.Group>
                <Form.Label>Phone</Form.Label>
                <Form.Control
                  type="text"
                  name="phone"
                  value={phone}
                  onChange={handleChange}
                  placeholder="Enter your phone"
                  required
                  style={inputBoxStyle}
                />
                <span style={errorMessageStyle}>{formErrors.phone}</span>
              </Form.Group>
              <Form.Group>
                <Form.Label>Country</Form.Label>
                <Form.Control
                  as="select"
                  name="country"
                  onChange={handleChange}
                  value={country}
                  required
                  style={inputBoxStyle}
                  className="country-dropdown"
                >
                  {countriesList.map((country, index) => (
                    <option key={country.code + index} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </Form.Control>
                {/* <span style={errorMessageStyle}>{formErrors.country}</span> */}
              </Form.Group>
              <Form.Group>
                <Form.Label>Street Address</Form.Label>
                <Form.Control
                  type="text"
                  name="streetAddress"
                  value={streetAddress}
                  onChange={handleChange}
                  placeholder="Enter street address"
                  required
                  style={inputBoxStyle}
                />
                <span style={errorMessageStyle}>
                  {formErrors.streetAddress}
                </span>
              </Form.Group>
              <Form.Group>
                <Form.Label>Postal Code / ZIP</Form.Label>
                <Form.Control
                  type="number"
                  name="postalCode"
                  value={postalCode}
                  onChange={handleChange}
                  placeholder="Enter postal code"
                  required
                  style={inputBoxStyle}
                />
                <span style={errorMessageStyle}>{formErrors.postalCode}</span>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="column2">
                {props.subscriptionId ? (
                  <Form.Label style={headingStyle}>PLAN DETAILS</Form.Label>
                ) : (
                  <Form.Label style={headingStyle}>PRODUCT DETAILS</Form.Label>
                )}
                <Form.Text>
                  <div className="d-flex align-items-center">
                    <strong>{plan.tier}</strong>
                  </div>
                </Form.Text>
                <Form.Text>
                  <div className="d-flex align-items-center">
                    <span>{plan.description}</span>
                  </div>
                </Form.Text>
              </Form.Group>

              <Form.Group controlId="column3">
                <Form.Label style={headingStyle}>YOUR ORDER</Form.Label>
                <Form.Group>
                  <Row>
                    <Col>
                      <Form.Text style={priceSectionStyle}>Plan</Form.Text>
                    </Col>

                    <Col>
                      <Form.Text style={priceSectionStyle}>Total</Form.Text>
                    </Col>
                  </Row>
                  <div style={lineStyle}></div>
                  <Row>
                    <Col>
                      {courseId ? (
                        <Form.Text>Business One Time</Form.Text>
                      ) : (
                        <Form.Text>
                          Business{" "}
                          {plan.substype !== "Free" && isMonthly
                            ? "(Monthly)"
                            : "(Yearly)"}
                        </Form.Text>
                      )}
                    </Col>
                    <Col>
                      <Form.Text> ${orderPrice}</Form.Text>
                    </Col>
                  </Row>
                  <div style={lineStyle}></div>
                  <Row>
                    <Col>
                      <Form.Text style={priceSectionStyle}>Taxes</Form.Text>
                    </Col>
                    <Col>
                      <Form.Text> ${orderTax}</Form.Text>
                    </Col>
                  </Row>
                  <div style={lineStyle}></div>
                  <Row>
                    <Col>
                      <Form.Text style={priceSectionStyle}>Total</Form.Text>
                    </Col>
                    <Col>
                      <Form.Text style={priceSectionStyle}>
                        {" "}
                        ${orderTotal}
                      </Form.Text>
                      <Form.Text style={{ "font-style": "italic" }}>
                        Currency in AUD
                      </Form.Text>
                    </Col>
                  </Row>
                </Form.Group>
              </Form.Group>
              <Form.Group>
                <Button
                  style={{ backgroundColor: brandColor, border: "none" }}
                  onClick={handleSubmit}
                  type="submit"
                  disabled={disableNextBtn}
                >
                  {plan.substype !== "Free" ? "Next" : "Subscribe"}
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
}
