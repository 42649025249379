import React, { useState, useEffect } from "react";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { userInfo } from "../../helper/service";
import "swiper/css";
import "swiper/css/navigation";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const BrandColor = localStorage.getItem("subadminBrandColor");

export default function SocialwallGrid(props) {
  const [socialtier, setSocialtier] = useState("");
  const [visiblePosts, setVisiblePosts] = useState(10);

  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    userInfo(userId).then((data) => {
      setSocialtier(data.result.validUser.subsType);
    });
  }, []);

  const loadMorePosts = async () => {
    let totalPosts = visiblePosts + 10;
    setVisiblePosts(totalPosts);
  };

  const bindData = () => {
    if (props.postData && props.postData.length > 0) {
      const userId = localStorage.getItem("user_id");
      return (
        <>
          <Container>
            <Row
              key={Math.random()}
              xs={1}
              sm={2}
              md={3}
              lg={4}
              xl={5}
              className="g-4"
            >
              {(
                props.postData.length > 0 &&
                props.postData
                  .sort(
                    (a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt)
                  )
                  .slice(0, visiblePosts)
              ).map((res, index) => {
              //  let memberCount=[res?.userId.find(u=>u.userid)]
                let userIdCheck = props.postData[index]?.userId?.find(
                  (i) => i?.userid == userId
                );
                if (
                  props.postData[index]?.memberAccessLevel?.includes(
                    socialtier
                  ) ||
                  userIdCheck
                ) {
                  var posts = 0;
                  if (res?.posts) {
                    posts = res?.posts?.length;
                  }
                  return (
                    <Col
                      key={index}
                      onClick={() => {
                        navigate(`/social-wall/wall-detail/${res._id}`);
                      }}
                    >
                      <div className="item">
                        <div className=" w-100">
                          <img
                            src={res?.image}
                            className="img-over w-100"
                            alt={res?.title}
                          />
                        </div>
                     

                        <h5 className="slide-title">{res?.title}</h5>
                        <p className="slide-text">
                          {posts} {posts <= 1 ? "Post" : "Posts"}
                        </p>
                        <p className="slide-text">
                          {res?.socialWallUsers?.length}{" "}
                          {res?.socialWallUsers?.length <= 1 ? "Member" : "Members"}
                        </p>
                      </div>
                    </Col>
                  );
                } else {
                  var posts = 0;
                  if (res?.posts) {
                    posts = res?.posts?.length;
                  }
                  return (
                    <Col
                      key={index}
                      onClick={() => {
                        navigate(`/social-wall/wall-detail/${res._id}`);
                      }}
                    >
                      <div className="item">
                        <div className=" w-100">
                          <img
                            src={res?.image}
                            className="img-over w-100"
                            alt={res?.title}
                          />
                        </div>
                        <h5 className="slide-title">{res?.title}</h5>

                        <p className="slide-text">
                          {posts} {posts <= 1 ? "Post" : "Posts"}
                        </p>
                        <p className="slide-text">
                          {res?.userId?.length}{" "}
                          {res?.userId?.length <= 1 ? "Member" : "Members"}
                        </p>
                      </div>
                    </Col>
                  );
                }
              })}
            </Row>
          </Container>
        </>
      );
    } else {
      return "Currently, there are no social walls available for you.";
    }
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-11">{bindData()}</div>
      </div>
      {visiblePosts <= props.postData?.length && (
        <div className="flex d-flex justify-content-center">
          <button
            className="btn-load-more"
            style={{ backgroundColor: BrandColor }}
            onClick={loadMorePosts}
          >
            Load More
          </button>
        </div>
      )}
    </>
  );
}
