import React, {useEffect, useState} from "react";
import { IFolder } from "../../interfaces/interfaces";
import { useNavigate } from "react-router-dom";

type FolderCardProps = {
  folder: IFolder;
  setSelectedFolder?: React.Dispatch<React.SetStateAction<IFolder | null>>;
  setShowFoldersListModal?: React.Dispatch<React.SetStateAction<boolean>>;
};

function FolderCard({ folder, setSelectedFolder, setShowFoldersListModal }: FolderCardProps) {
  const navigate = useNavigate();
  const [BrandColor, setBrandColor] = useState('')
  const [isHovered, setIsHovered] = useState(true)
  useEffect(()=>{
    const brandColor = localStorage.getItem('subadminBrandColor');
    if(brandColor){
      setBrandColor(brandColor)
    }
  },[])
  const handleClick = () => {
    if (setSelectedFolder && setShowFoldersListModal) {
      setSelectedFolder(folder);
      // setShowFoldersListModal(true);
      if (folder._id) navigate(`/folder/${folder._id}`);
    }
  };

  return (


    <div className="highlight-card" onClick={handleClick}>
      <img src={folder.image} className="card-img-top cursor-pointer img-over w-100" alt={folder.title} />
      <h5 className="slide-title pl-2 cursor-pointer ">

         {folder && folder.title && folder.title.length > 25 ? folder.title.slice(0, 25) + "..." : folder?.title}
      </h5>


    </div>
  );
}

export default FolderCard;
