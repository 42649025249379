import React, { useCallback, useEffect, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { getAllCourses } from "../../helper/service";
import { Spinner } from "react-bootstrap";
import {
  IgetRecentCoursesResponse,
  IResponseError,
} from "../../interfaces/interfaces";
// import slideleft1 from "../../images/slide-left1.svg";
// import slideright1 from "../../images/slide-right1.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Mousewheel, Pagination } from "swiper/modules";
import "swiper/css";

import { AxiosError } from "axios";
import CourseCard from "./CourseCard";

type AllCoursesFoldersListProps = {
  searchQuery?: string;
};

function AllCoursesFoldersList(props: AllCoursesFoldersListProps) {
  const [BrandColor, setBrandColor] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  useEffect(() => {
    const brandColor = localStorage.getItem("subadminBrandColor");
    if (brandColor) {
      setBrandColor(brandColor);
    }
  }, []);

  const getAllCoursesQuery = useQuery<
    IgetRecentCoursesResponse,
    AxiosError<IResponseError>
  >({
    queryKey: ["getAllCourses"],
    queryFn: () => {
      return getAllCourses();
    },
  });
  // const [searchQuery] = useSearchQuery();
  const filteredCourses = useCallback(() => {
    return getAllCoursesQuery.data?.result?.filter((course) => {
      if (!props.searchQuery) return true;
      return course?.title
        ?.toLowerCase()
        .includes(props.searchQuery.toLowerCase());
    });
  }, [getAllCoursesQuery.data?.result, props.searchQuery]);

  if (getAllCoursesQuery.isLoading) {
    return <Spinner animation="border" />;
  }

  if (
    getAllCoursesQuery.isError ||
    !getAllCoursesQuery.data?.result ||
    !Array.isArray(getAllCoursesQuery.data?.result)
  ) {
    if (getAllCoursesQuery.error?.response?.data?.message) {
      return (
        <p className="p-5">
          {getAllCoursesQuery.error?.response?.data?.message}
        </p>
      );
    }
    return (
      <p className="text-danger p-5">
        Something went wrong! Failed to load Courses
      </p>
    );
  }

  if (filteredCourses()?.length === 0) {
    return (
      <p className="p-5">
        No courses found
        {props.searchQuery && (
          <span>
            {" "}
            for <i className="text-danger">"{props.searchQuery}"</i>
          </span>
        )}
      </p>
    );
  }

  return (
    <Swiper
      modules={[Navigation, Mousewheel, Pagination]}
      navigation={true}
      spaceBetween={25}
      pagination={true}
      slidesPerView={1}
      breakpoints={{
        400: {
          width: 400,
          slidesPerView: 2,
        },
        768: {
          width: 768,
          slidesPerView: 3,
        },
      }}
    >
      {filteredCourses()?.map((course) => {
        let userId = localStorage.getItem("user_id");
        let studentEnroll = course?.students?.includes(`${userId}`);
        let courseSchedule = course?.schedule;
        let courseScheduleDate = "";
        let formattedCurrentDateTime = "";
        if (courseSchedule) {
          var originalDate = new Date(courseSchedule);
          var selectedTimeZone = localStorage.getItem("websiteTimeZone");
          if (selectedTimeZone) {
            // Change this to your desired timezone
            courseScheduleDate = originalDate.toLocaleString("en-US", {
              timeZone: selectedTimeZone,
            });

            var currentDateTime = new Date();
            formattedCurrentDateTime = currentDateTime.toLocaleString("en-US", {
              timeZone: selectedTimeZone,
            });
          }
        }
        let lessonCount =
          typeof course?.tLesson === "number" && course?.tLesson > 0;
        if (!studentEnroll && lessonCount) {
          if (courseSchedule) {
            if (
              new Date(courseScheduleDate) < new Date(formattedCurrentDateTime)
            ) {
              return (
                <SwiperSlide
                  key={course._id}
                  className="discover-slider courses-slider owl-carousel owl-theme highlight-card"
                >
                  <CourseCard
                    course={course}
                    showEnrollBtn={true}
                    showLink={false}
                  />
                </SwiperSlide>
              );
            }
          } else {
            return (
              <SwiperSlide
                key={course._id}
                className="discover-slider courses-slider owl-carousel owl-theme highlight-card"
              >
                <CourseCard
                  course={course}
                  showEnrollBtn={true}
                  showLink={false}
                />
              </SwiperSlide>
            );
          }
        }
      })}
    </Swiper>
  );
}

export default AllCoursesFoldersList;
