import React, { useEffect, useState } from "react";
import { NavLink, Navigate } from "react-router-dom";

import Sidebar from "./Sidebar";
import NotificationDropDown from "./NotificationDropDown";

import { userInfo } from "../../helper/service";
import { useAuth } from "../../contexts/auth";

import hexToRgba from "hex-to-rgba";
import adminimg from "../../images/dashboard/admin-img.png";

export default function Header() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [photo, setPhoto] = useState(adminimg);
  const [isRedirect, setIsRedirect] = useState(false);
  const { user, logOutAction } = useAuth();

  useEffect(() => {
    if (user !=null && user !=undefined) {
      console.warn("header found user: ", user);
      if (user?.status != "Active") {
        alert("Your status is not Active please contact your instructor");
        setIsRedirect(true);
      }
      updateData(user);
    } else {
      const userId = localStorage.getItem("user_id");
      const partnerId = localStorage.getItem("subadminId");
      userInfo(userId).then((data) => {
        const index = data?.result?.validUser?.subadminsId.findIndex(subadmin => subadmin.subadminId._id === partnerId);
       if(data?.result?.validUser?.subscriptionId[index]?.status !="In-Active")
        localStorage.setItem(
          "subscriptionId",
          data?.result?.validUser?.subscriptionId[index]?.subsId?._id
        );
        //localStorage.setItem('subscriptionId',data.result.validUser.subscriptionId[0]['subsId']['_id']);
        var systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let subadminTimeZone =
          data.result.validUser.subadminsId[0].subadminId?.timezone;
        localStorage.setItem(
          "websiteTimeZone",
          subadminTimeZone ? subadminTimeZone : systemTimeZone
        );
        var imgs = "";
        if (data.result.validUser.image) {
          imgs = data.result.validUser.image;
          localStorage.setItem("memberImg", imgs);
        } else {
          imgs = localStorage.getItem("subadminImage");
        }
        setFirstName(data.result.validUser.firstName);
        setLastName(data.result.validUser.lastName);
        setPhoto(imgs);

        updateData(data.result.validUser);

      });

      if (!userId) {
        logout();
      }
    }
  }, []);

  const updateData = (user) => {
    const partnerId = localStorage.getItem("subadminId");
    const index = user?.subadminsId.findIndex(subadmin => subadmin.subadminId._id === partnerId);
   if(user?.subscriptionId[index]?.status !="In-Active")
    localStorage.setItem(
      "subscriptionId",
      user?.subscriptionId[index]?.subsId?._id
    );
    //localStorage.setItem('subscriptionId',data.subscriptionId[0]['subsId']['_id']);
    var systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let subadminTimeZone = user.subadminsId[0].subadminId?.timezone;
    localStorage.setItem(
      "websiteTimeZone",
      subadminTimeZone ? subadminTimeZone : systemTimeZone
    );
    var imgs = "";
    if (user.image) {
      imgs = user.image;
      localStorage.setItem("memberImg", imgs);
    } else {
      imgs = localStorage.getItem("subadminImage");
    }
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setPhoto(imgs);
  };

  const logout = () => {
    logOutAction();
    setIsRedirect(true);
  };

  const BrandColor = localStorage.getItem("subadminBrandColor") ?? "";
  const bgcolor = { backgroundColor: BrandColor };
  const fontcolor = { color: BrandColor };
  const membershipName = localStorage.getItem("membershipName");
  const subadminImage = localStorage.getItem("subadminImage");

  var OpacityColor = hexToRgba(BrandColor, "0.7");
  const dashboardColor = { backgroundColor: OpacityColor };

  if (isRedirect) {
    const currentUrl = window.location;
    return <Navigate to={`/login?redirect=${currentUrl}`} />;
  }

  return (
    <React.Fragment>
      <nav className="main-header navbar navbar-expand" style={dashboardColor}>
        {/* Right navbar links */}

        <ul className="navbar-nav">
          <li className="nav-item d-lg-none">
            <a
              className="nav-link collapse-sidebar"
              data-widget="pushmenu"
              href="#"
              role="button"
            >
              <i className="fas fa-bars"></i>
            </a>
          </li>
          {/* <li className="nav-item">
                <a className="nav-link p-0 pl-2" href="/dashboard">
                  <img src={NewLogo} alt="logo" width={80}/>
                </a> 
              </li> */}
        </ul>

        <ul className="navbar-nav ml-auto">
          {/* Navbar Search */}
          <li className="nav-item" key="nav-search">
            {/* <a
                className="nav-link"
                data-widget="navbar-search"
                href="#"
                role="button"
                style={fontcolor}
              >
                <i className="fas fa-search" />
              </a> */}
            <div className="navbar-search-block">
              <form className="form-inline">
                <div className="input-group input-group-sm">
                  <input
                    className="form-control form-control-navbar"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-navbar" type="submit">
                      <i className="fas fa-search" />
                    </button>
                    <button
                      className="btn btn-navbar"
                      type="button"
                      data-widget="navbar-search"
                    >
                      <i className="fas fa-times" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li>
          {/* Notifications Dropdown Menu */}
          <li className="nav-item" key="nav-notifications">
            <NotificationDropDown />
          </li>

          <li className="nav-item" key="nav-userinfo">
            <p style={fontcolor} className="hidden-xs nav-link user-name">
              {firstName} {lastName} <br />
            </p>
          </li>
          <li className="nav-item dropdown user user-menu" key="nav-profile">
            <a href="#" className="nav-link" data-toggle="dropdown">
              <img src={photo} className="user-image" alt="User Image" />
              <i style={fontcolor} className="fas fa-angle-down" />
            </a>
            <div
              className="dropdown-menu dropdown-menu-right"
              style={{ width: "140px", marginTop: "9px", fontSize: "14px" }}
            >
              {/* <a href="#" className="dropdown-item">Business</a>
                  <a href="#" className="dropdown-item">Edit Appearance</a>
                  <a href="#" className="dropdown-item">Personal Profile </a>
                  <a href="#" className="dropdown-item">Account settings </a>
                  <a href="#" className="dropdown-item">Statistics</a> */}
              {/* <a href="#" className="dropdown-item">Log out</a> */}
              <NavLink to="/edit-account" className="dropdown-item">
                Edit Appearance
              </NavLink>
              <NavLink to="/account-details" className="dropdown-item">
                Personal Profile
              </NavLink>
              <a
                href="#"
                className="dropdown-item"
                onClick={() => {
                  if (window.confirm("Are you sure you want to logout?")) {
                    logout();
                  }
                }}
              >
                Log out
              </a>
            </div>
          </li>
        </ul>
      </nav>

      <aside className="main-sidebar sidebar-dark-primary" style={bgcolor}>
        <a
          className="nav-link collapse-sidebar"
          data-widget="pushmenu"
          href="#"
          role="button"
        >
          <i className="fas fa-bars" />
        </a>
        <a href="#" className="brand-link company-info" style={bgcolor}>
          <img src={subadminImage} className="brand-image" alt="logo" />
          {/* <p className="company-name brand-text">{companyName} <br /><small>{membershipName}</small></p> */}
          <p className="company-name brand-text">{membershipName}</p>
        </a>
        <Sidebar />
      </aside>
    </React.Fragment>
  );
}
