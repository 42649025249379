import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import JoinEventBtn from "./JoinEventBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { faRectangleXmark } from "@fortawesome/free-solid-svg-icons";
import EventComments from "./EventComments";
import submit2 from "../../images/enduser/submit2.svg";
import calendar from "../../images/enduser/calendar-sm.svg";
import ReactPlayer from "react-player";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function EventModal({
  isOpen,
  closeModal,
  event,
  comments,
  userJoinedEvent,
  handleRegisterSuccess,
  savePostcomment,
  shortenFileName
}) {
  const [comment, setComment] = useState("");

  return (
    <Modal
      show={isOpen}
      onHide={closeModal}
      className="viewEventModal"
      id="viewEventModal"
      backdrop="static"
    >
      <Modal.Header className="border-0 align-items-center position-relative">
        <h5 className="modal-title">{
          event.eventName.length <= 15 ?
            (event.eventName) :
            (<OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id="tooltip-left">
                  {event.eventName}
                </Tooltip>
              }>
              <span>{event.eventName.substring(0, 13) + '...'}</span>
            </OverlayTrigger>)
        }
        </h5>
        <div className="position-absolute" style={{ left: '50%', transform: 'translateX(-50%)' }}>
          <JoinEventBtn
            eventId={event.eventId}
            alreadyJoined={userJoinedEvent}
            onSuccess={handleRegisterSuccess}
          />
        </div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={closeModal}
        >
          <FontAwesomeIcon icon={faRectangleXmark} className="close-modal" />
        </button>
      </Modal.Header>

      <div className="event-img mb-3">
        {event.eventImg?.includes(".png") ||
          event.eventImg?.includes(".jpeg") ||
          event.eventImg?.includes(".jpg") ||
          event.eventImg?.includes("jfif") ||
          event.eventImg?.includes("webp") ||
          event.eventImg?.includes("gif") ||
          event.eventImg?.includes("bmp") ||
          event.eventImg?.includes("tiff") ||
          event.eventImg?.includes("svg") ||
          event.eventImg?.includes("ico") ? (
          <>
            <img src={event.eventImg} className="img-fluid" />
          </>
        ) : event.eventImg?.includes("mp4") ||
          event.eventImg?.includes("avi") ||
          event.eventImg?.includes("mkv") ||
          event?.eventImg?.includes("webm") ? (
          <div>
            <ReactPlayer
              config={{
                file: { attributes: { controlsList: "nodownload" } },
              }}
              url={event.eventImg}
              controls={true}
              playing={false}
              width="400"
              height="200px"
            />
          </div>
        ) : event.eventImg?.includes("mp3") ||
          event.eventImg?.includes("mpeg") ||
          event.eventImg?.includes("wav") ||
          event.eventImg?.includes("ogg") ||
          event.eventImg?.includes("flac") ||
          event.eventImg?.includes("aac") ? (
          <div>
            <ReactPlayer
              config={{
                file: { attributes: { controlsList: "nodownload" } },
              }}
              url={event.eventImg}
              controls={true}
              playing={false}
              width="400"
              height="80px"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="event-img" style={{ marginTop: "1em" }}>
        {userJoinedEvent ? (
          Array.isArray(event.coverGalleryEvent) ? (
            event.coverGalleryEvent.map((file, index) => (
              <a
                key={index}
                href={file}
                title={file.substring(file.lastIndexOf('/') + 1)}
                download
                target="_blank"
                className="d-flex justify-content-between align-items-center"
              >
                <span className="ml-3">{shortenFileName(file.substring(file.lastIndexOf('/') + 1))}</span>
                <img
                  src={require("../../images/download (2).png")}
                  alt="download img"
                  style={{ marginRight: "10px"}}
                  className="download-data"
                />
              </a>
            ))
          ) : (
            event.coverGalleryEvent && (
              <a
                href={event.coverGalleryEvent}
                title={event.coverGalleryEvent.substring(event.coverGalleryEvent.lastIndexOf('/') + 1)}
                download
                target="_blank"
                className="d-flex justify-content-between align-items-center"
              >
                <span className="ml-3">{shortenFileName(event.coverGalleryEvent.substring(event.coverGalleryEvent.lastIndexOf('/') + 1))}</span>
                <img
                  src={require("../../images/download (2).png")}
                  alt="download img"
                  style={{ marginRight: "10px"}}
                  className="download-data"
                />
              </a>
            ))) : (
          <span className="event-type" style={{ margin: "0 0 0 20px" }}>Register to download event files</span>
        )}
      </div>
      <Modal.Body>
        <div className="d-flex justify-content-between align-items-start flex-wrap">
          <img src={calendar} />
          <div className="event-date mr-auto">
            {new Date(event.eventDate).toLocaleDateString()} -{" "}
            {new Date(event.eventEndDate).toLocaleDateString()}
            <br />
            Event from : {event.startTime} - {event.endTime} {event.timezone}
          </div>{" "}
          <div>
            {userJoinedEvent ? (
              event.url ? (
                <>
                  <a
                    href={`${event.url}`}
                    target="_blank"
                    className="event-link"
                  >
                    EVENT LINK <FontAwesomeIcon icon={faLink} />
                  </a>
                  <br />
                </>
              ) : (
                <span className="event-type">Physical event</span>
              )
            ) : (
              <span className="event-type">Register to view the link</span>
            )}
          </div>
        </div>
        <div className="event-description">
          <h3 className="title">Description</h3>
          <div
            className=" description-content text"
            dangerouslySetInnerHTML={{ __html: event.description }}
          ></div>
        </div>
        {userJoinedEvent && (
          <div className="comments">
            <EventComments comments={comments} />
            <form>
              <div className="new-msg d-flex">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Type your message here…"
                  name="comment"
                  onChange={(e) => {
                    e.preventDefault();
                    setComment(e.target.value);
                  }}
                  value={comment}
                />
                <img
                  src={submit2}
                  className="ml-2"
                  onClick={() => {
                    savePostcomment(comment, event.eventId);
                  }}
                />
              </div>
            </form>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
