import React, { Component } from 'react';
import { Navigate } from "react-router-dom";
import logo from '../images/logo.png';
import membership from '../images/membership-bg.svg';

const initialState = {
  fields: {},
  errors: {},
  isSignup: false,
  error: false,
  firstName: '',
  lastName: '',
  maskEmail: '',
  maskPhone: '',
  isredirect: false,
  isredirect1: false,
}
export default class Verify extends Component {

  constructor(props) {
    super(props);
    this.state = initialState;
    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  onChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
  }

  submitForm(e) {
    var email1 = localStorage.getItem('email');
    var mobile1 = localStorage.getItem('mobile');
    var password1 = localStorage.getItem('password');

    e.preventDefault();
    const { email, mobile, } = this.state.fields;

    if (password1 === "") {
      if (email === email1){// && mobile === mobile1) {
        this.setState({ isredirect: true })
      } else {
        alert("Please enter a valid email address for verification.")
      }
    } else {
      this.setState({ isredirect1: true })
    }
  }

  validate = () => {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Username is required";
    } else {
      errors["email"] = "";
    }

    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*Password is required";
    } else {
      errors["password"] = "";
    }
    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  componentDidMount() {
    const email = localStorage.getItem('email');
    const firstName = localStorage.getItem('firstName');
    const lastName = localStorage.getItem('lastName');
    const mobile = localStorage.getItem('mobile');

    console.log(email)
    console.log(mobile)
    let str = email;
    str = str.split('');
    let finalArr = [];
    let len = str.indexOf('@');
    str.forEach((item, pos) => {
      (pos >= 1 && pos <= len - 2) ? finalArr.push('*') : finalArr.push(str[pos]);
    })
    const maskEmail = finalArr.join('');
    const phone = mobile;
    const phoneString = phone.toString();

    var maskPhone = phoneString.substr(6, 4);

    this.setState({
      firstName: firstName,
      lastName: lastName,
      maskEmail: maskEmail,
      maskPhone: maskPhone
    })
  }

  render() {

    var membershipName = localStorage.getItem('membershipName');
    var companyName = localStorage.getItem('companyName');

    document.body.style.backgroundImage = "url(" + membership + ")";

    if (this.state.isredirect) {
      return <Navigate to="/password-update" />;
    }

    if (this.state.isredirect1) {
      return <Navigate to="/welcome" />;
    }

    return (
      <section id="user-membership-login" className="d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="card">
                <div className="card-body text-center">

                  <div className="login-logo">
                    <img src={logo} className="img-fluid mx-auto d-block" alt="logo" />
                  </div>

                  <div className="company-info d-flex justify-content-center align-items-center membership-box-color">
                    <img src="images/company-logo.svg" className="mr-2 img-fluid" alt="" />
                    <p className="company-name">{companyName} <br /><small>{membershipName}</small></p>
                  </div>
                  <p className="user-membership-login-text">Are you a {this.state.firstName} {this.state.lastName}?</p>
                  <form name="form" onSubmit={this.submitForm}>
                    <div className="form-group">
                      <label>{this.state.maskEmail}</label>
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <span className="fas fa-envelope" />
                          </div>
                        </div>
                        <input type="text" className="form-control" placeholder="Verify Email Address" name="email" onChange={this.onChange} />
                        <span className="errormessage">{this.state.errors.email}</span>
                      </div>
                    </div>

                    {/* <div className="form-group">
                      <label>****-****-{this.state.maskPhone}</label>
                      <div className="input-group input2">
                        <div className="input-group-prepend">
                          <div className="input-group-text">
                            <span className="fas fa-lock" />
                          </div>
                        </div>
                        <input type="text" className="form-control" placeholder="Verify Mobile Number" name="mobile" onChange={this.onChange} />
                        <span className="errormessage">{this.state.errors.mobile}</span>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-12 text-center">
                        <button type="submit" className="btn btn-info">Verify</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}