import { useMutation } from "@tanstack/react-query";
import React, { useState} from "react";
import { joinEvent } from "../../helper/service";
import SuccessPopup from "../Setting/SuccessPopup";
import "../../styles/joinEventBtn.scss";

function JoinEventBtn({ eventId, onSuccess, alreadyJoined }) {
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const joinEventMutation = useMutation({
    mutationFn: () => {
      return joinEvent(eventId);
    },
  });

  const handleJoinEvent = () => {
    joinEventMutation.mutate(
      {},
      {
        onSuccess: () => {
          setShowSuccessPopup(true);
          if (onSuccess) {
            onSuccess?.();
          }
        },
      }
    );
  };

  if (alreadyJoined || joinEventMutation.isSuccess) {
    return (
      <>
        <span className="text-success ml-auto">Registered</span>
        {showSuccessPopup && (
          <SuccessPopup message="Event registered successfully" />
        )}
      </>
    );
  }

  return (
    <button type="button" className="ml-auto joinBtn" onClick={handleJoinEvent}>
      {joinEventMutation.isPending ? (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      ) : null}
      {joinEventMutation.isError ? (
        <span className="text-danger">
          Error: {joinEventMutation.error.message}
        </span>
      ) : null}
      {!joinEventMutation.isPending && !joinEventMutation.isError && (
        <span>Register</span>
      )}
    </button>
  );
}
export default JoinEventBtn;