import {
  PaymentElement,
  LinkAuthenticationElement
} from '@stripe/react-stripe-js'
import React, { useState, useEffect } from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import countriesList from '../../components/country.json';
import Loader from '../Loader';

import { userInfo, getMembershipLevel, subscribefree, startSubscription, getSaleSelectedCourse, startPurchaseCourse } from "../../helper/service";

import secureCheckout from '../../images/checkout/secure-checkout.png'

const TaxRate = 0.10;

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
console.log(props,"props data")
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    postalCode: '',
    streetAddress: '',
    country: "Australia",
  });
  const [isChecked, setIsChecked] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [brandColor, setBrandColor] = useState('');
  const [plan, setPlan] = useState({});
  const [isMonthly, setIsMonthly] = useState(true);
  const [orderPrice, setOrderPrice] = useState(0.00);
  const [orderTax, setOrderTax] = useState(0.00);
  const [orderTotal, setOrderTotal] = useState(0.00);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    var isMonthlyPayment = props.subscriptionFrequency === 'monthly';
    setIsMonthly(isMonthlyPayment);
    setBrandColor(localStorage.getItem('subadminBrandColor'));

    const userId = localStorage.getItem('user_id');
    userInfo(userId).then(data => {
      let userData = {
        fullName: data.result.validUser.firstName + ' ' + data.result.validUser.lastName,
        email: data.result.validUser.email,
        phone: data.result.validUser.mobile
      }
      setFormData(userData);

      let subsId = props.subscriptionId;
      if (subsId) {
        getMembershipLevel(subsId).then(subs => {
          if (subs?.result?.validSubs) {
            var subscription = subs?.result?.validSubs;
            if (subscription) {
              setPlan(subscription);
              var orderMonthlyPrice = (Math.round(subscription.amountMonthly * 100) / 100);
              var orderYearlyPrice = (Math.round(subscription.amountYearly * 100) / 100);

              var originalPrice = isMonthlyPayment ? orderMonthlyPrice : orderYearlyPrice;
              var currentPrice = originalPrice / 1.1;
              var tax = (currentPrice * TaxRate);

              setOrderPrice(currentPrice.toFixed(2));
              setOrderTax(tax.toFixed(2));
              setOrderTotal(originalPrice.toFixed(2));
            }
          }
        })
      }
      else if (props.courseId) {
        getSaleSelectedCourse(props.courseId).then(course => {
          let selectedCourse = course.result[0];
          let singleCourse = {
            tier: selectedCourse.title,
            description: selectedCourse.shortDescription
          }
          var orderPrice = (Math.round(selectedCourse.amount * 100) / 100);
          var currentPrice = orderPrice / 1.1;
          var tax = (currentPrice * TaxRate);
          setPlan(singleCourse);
          setOrderPrice(currentPrice.toFixed(2));
          setOrderTax(tax.toFixed(2));
          setOrderTotal(orderPrice.toFixed(2));

        })
      }
    });
  }

  const { fullName, email, phone, postalCode, streetAddress, country } = formData;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const validate = () => {
    let errors = {};

    if (!fullName) {
      errors.fullName = '*Required';
    }

    if (!email) {
      errors.email = '*Required';
    } else {
      const emailRegex = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
      if (!emailRegex.test(email)) {
        errors.email = '*Required';
      }
    }

    if (!country) {
      //errors.country = '*Required';
    }
    if (!streetAddress) {
      errors.streetAddress = '*Required';
    }
    if (!postalCode) {
      errors.postalCode = '*Required';
    }
    if (!phone) {
      errors.phone = '*Required';
    }

    if (!isChecked) {
      errors.isChecked = '* Please accept terms & conditions';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { fullName, email, phone, country, streetAddress, postalCode } = formData;
    console.log('dataTest', formData);
    let data = {}
    if (props.subscriptionId) {

      data = {
        fullName,
        email: email?.trim(),
        phone: phone?.trim(),
        country,
        streetAddress,
        postalCode,
        subscriptionId: props.subscriptionId,
        subscriptionType: props.subscriptionFrequency
      };
    }
    else if (props.courseId) {
      data = {
        fullName,
        email: email?.trim(),
        phone: phone?.trim(),
        country,
        streetAddress,
        postalCode,
        courseId: props.courseId,
        token: localStorage.getItem("token"),
        subadminId: localStorage.getItem("subadminId"),
      };
    }

    const dataIsValid = validate();

    if (dataIsValid) {

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsLoading(true);

      // NEEDED for capturing before creating intent      
      // // Trigger form validation and wallet collection
      // const {error: submitError} = await elements.submit();
      // if (submitError) {
      //   setMessage(submitError.message);
      //   return;
      // }


      // var result = await createMemberPayment(props.subscriptionId, props.subscriptionFrequency);
      // console.log(result, 'strip result');
      // const clientSecret = result.clientSecret;


      //var subResult = await subscribefree(props.subscriptionId);
      const subadminId = localStorage.getItem('subadminId');
      data.subadminId = subadminId;
      var subResult;
      var returnUrl;
      if (props.subscriptionId) {
        returnUrl = `${window.location.origin}/complete-subscription?subscriptionId=${props.subscriptionId}`;
        subResult = await startSubscription(data);
      }
      else if (props.courseId) {
        returnUrl = `${window.location.origin}/complete-course?courseId=${props.courseId}`;
        subResult = await startPurchaseCourse(data);
      }

      if (subResult) {
        const { error } = await stripe.confirmPayment({
          elements,
          //clientSecret,
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: returnUrl
          },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
          //await initialize();
        } else {
          setMessage("An unexpected error occured.");
        }
      }

      setIsLoading(false);
    }
  };

  const headingStyle = {
    color: brandColor,
    fontWeight: 'bold',
    fonnSize: '1.5rem',
  }

  const inputBoxStyle = {
    backgroundColor: "white",
    borderColor: "#D2D2D2",
    height: "2.5em",
  }

  const priceSectionStyle = {
    fontWeight: "600",
    fonnSize: "1.5rem",
  }
  const errorMessageStyle = {
    color: "red",
    fontSize: '0.7rem',
  }
  const bullets = {
    width: "1em",
    height: "1em",
    backgroundColor: brandColor,
    borderRadius: "50%",
    marginTop: "0.5em",
    marginRight: "0.9em",
  }
  const bulletSmall = {
    width: "0.75em",
    height: "0.7em",
    backgroundColor: brandColor,
    borderRadius: "50%",
    marginTop: "0.5em",
    marginRight: "0.7em",
  }
  const lineStyle = {
    width: "55%",
    marginLeft: "5%",
    marginRight: "20%",
    marginTop: '.3em',
    marginBottom: '.3em',
    height: "1px",
    backgroundColor: "#D2D2D2",
  }

  return (
    <>
      {isLoading ? (<Loader />) : (<></>)}
      <Form>
        <Row>
          <Col>
            <Form.Label style={headingStyle}>BILLING DETAILS</Form.Label>
            <Form.Group>
              <span style={errorMessageStyle}>*</span>
              <span style={{ fontSize: "0.7rem" }}> required fields</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                value={fullName} // Use fullName from formData
                onChange={handleChange}
                placeholder="Enter your full name"
                required
                style={inputBoxStyle}
              />
              <span style={errorMessageStyle}>{formErrors.fullName}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>Country</Form.Label>
              <Form.Control
                as="select"
                name="country"
                onChange={handleChange}
                value={country}
                required
                style={inputBoxStyle}
                className='country-dropdown'
              >
                {countriesList.map((country, index) => (
                  <option key={country.code + index} value={country.name}>
                    {country.name}
                  </option>
                ))}
              </Form.Control>
              {/* <span style={errorMessageStyle}>{formErrors.country}</span> */}
            </Form.Group>
            <Form.Group>
              <Form.Label>Street Address</Form.Label>
              <Form.Control
                type="text"
                name="streetAddress"
                value={streetAddress}
                onChange={handleChange}
                placeholder="Enter street address"
                required
                style={inputBoxStyle}
              />
              <span style={errorMessageStyle}>{formErrors.streetAddress}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>Postal Code / ZIP</Form.Label>
              <Form.Control
                type="number"
                name="postalCode"
                value={postalCode}
                onChange={handleChange}
                placeholder="Enter postal code"
                required
                style={inputBoxStyle}
              />
              <span style={errorMessageStyle}>{formErrors.postalCode}</span>
            </Form.Group>
            <Form.Label style={headingStyle}>CONTACT INFORMATION</Form.Label>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
                style={inputBoxStyle}
              />
              <span style={errorMessageStyle}>{formErrors.email}</span>
            </Form.Group>
            <Form.Group>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={phone}
                onChange={handleChange}
                placeholder="Enter your phone"
                required
                style={inputBoxStyle}
              />
              <span style={errorMessageStyle}>{formErrors.phone}</span>
            </Form.Group>
          </Col>

          <Col>
            <Form.Group controlId="column2">
              <Form.Label style={headingStyle}>PLAN DETAILS</Form.Label>
              <Form.Text>
                <div className="d-flex align-items-center">
                  <strong>{plan.tier}</strong>
                </div>
              </Form.Text>
              <Form.Text>
                <div className="d-flex align-items-center">
                  <span>{plan.description}</span>
                </div>
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="column3">
              <Form.Label style={headingStyle}>YOUR ORDER</Form.Label>
              <Form.Group>
                <Row>
                  <Col>
                    <Form.Text style={priceSectionStyle}>Plan</Form.Text>
                  </Col>

                  <Col>
                    <Form.Text style={priceSectionStyle}>Total</Form.Text>
                  </Col>
                </Row>
                <div style={lineStyle}></div>
                <Row>
                  {props.subscriptionId ?
                    <Col>
                      <Form.Text >Business {isMonthly ? '(Monthly)' : '(Yearly)'}</Form.Text>
                    </Col> :
                    <Col>
                      <Form.Text >Business Onetime</Form.Text>
                    </Col>
                  }
                  <Col>
                    <Form.Text > ${orderPrice}
                    </Form.Text>
                  </Col>
                </Row>
                <div style={lineStyle}></div>
                <Row>
                  <Col>
                    <Form.Text style={priceSectionStyle}>Taxes</Form.Text>
                  </Col>
                  <Col>
                    <Form.Text> ${orderTax}</Form.Text>
                  </Col>
                </Row>
                <div style={lineStyle}></div>
                <Row>
                  <Col>
                    <Form.Text style={priceSectionStyle}>Total</Form.Text>
                  </Col>
                  <Col>
                    <Form.Text style={priceSectionStyle}> ${orderTotal}</Form.Text>
                    <Form.Text style={{ 'font-style': 'italic' }}>Currency in AUD</Form.Text>
                  </Col>
                </Row>
              </Form.Group>
            </Form.Group>
            {/* <Form.Group>
                                    <Form.Label type="text" placeholder="Input 2" style={headingStyle}>HAVE A COUPEN</Form.Label>
                                    <Row>
                                        <Col>
                                            <Form.Control type='text' style={{ height: "2.6em", witdth: "10em", backgroundColor: "white", borderColor: "#D2D2D2" }} />
                                        </Col>
                                        <Col>
                                            <Button style={{ backgroundColor: "#12AEB4", border: "none" }}>Apply</Button>
                                        </Col>
                                    </Row>
                                </Form.Group> */}
            <Form.Group>
              {/* <Form.Label style={headingStyle}>PAYMENT METHOD</Form.Label>
                                    <Form.Group>
                                        <Form.Check
                                            type="checkbox"
                                            label="Creadit Card (Payrix)"
                                            id="checkbox"
                                            style={inputBoxStyle}>
                                        </Form.Check>
                                        <Form.Control type='number' placeholder='Card Number' style={inputBoxStyle} />
                                        <Form.Control type='number' placeholder='Name on Card' style={inputBoxStyle} />
                                    </Form.Group> */}
              {/* <Form.Group>
                                        <Form.Check
                                            type="checkbox"
                                            label="BPAY"
                                            id="checkbox"
                                            style={inputBoxStyle}>
                                        </Form.Check>
                                        <Form.Control type='number' placeholder='Card Number' style={inputBoxStyle} />
                                    </Form.Group> */}

              <Form.Group>
                <PaymentElement id="payment-element" />
                {/* Show any error or success messages */}
                {message && <div id="payment-message">{message}</div>}
              </Form.Group>
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  id="checkboxId"
                  label="I have read and agree to the website terms and conditions"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  required
                />
                <span style={errorMessageStyle}>{formErrors.isChecked}</span>
              </Form.Group>
              <Form.Group>
                <Form.Text>
                  Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our <a target='blank' href='/privacy-policy'>privacy policy</a>.
                </Form.Text>
              </Form.Group>
              <Form.Group>
                <Button style={{ backgroundColor: brandColor, border: "none" }} onClick={handleSubmit} type='submit'>Place Order</Button>
              </Form.Group>
              <Form.Group>
                <img src={secureCheckout} />
              </Form.Group>
            </Form.Group>
          </Col>

        </Row>
      </Form>
    </>
  )
}