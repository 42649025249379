import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import placeholder from "../../images/placeholder.jpg";
import submit from "../../images/enduser/submit-button.svg";
import close from "../../images/enduser/modal-close-icon.svg";
import $ from "jquery";
import { getCourseLessonById, saveCoursecomment, coursecommentlist, getLiveLessonsModules } from "../../helper/service";
import { monthNames } from "../../helper/constants";

const ModuleLessons = (props) => {
    const [live, setLive] = useState("");
    const [draft, setDraft] = useState("");
    const [schedule, setSchedule] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [currentLesson, setCurrentLesson] = useState("");
    const [currentCourse, setCurrentCourse] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [goal, setGoal] = useState("");
    const [fileName, setFileName] = useState("");
    const [types, setTypes] = useState("");
    const [files, setFiles] = useState("");
    const [resource, setResource] = useState("");
    const [comment, setComment] = useState("");
    const [videocomment, setVideocomment] = useState("");
    const [isLoader, setIsLoader] = useState(false);

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    const listData = () => {
        if (props.courseId && props.userId && props.id) {
            localStorage.setItem("goToBackCourseOrModule", "Module")
            getLiveLessonsModules(props.userId, props.courseId, props.id).then((moduleLesson) => {
                console.log(moduleLesson.result.res, "Module Lessons");
                iterateFolders(moduleLesson.result, "live");
            });
        }
    };

    useEffect(() => {
        listData();
    }, [props.courseId, props.userId, props.id]);


    const iterateFolders = (datas, types) => {
        console.log("datas folders==== " + types, datas);
        if (datas?.res === undefined) return;
        if (datas.res.length > 0) {
            var i = 0;
            let sortedLesson=datas.res.sort((l1,l2)=>l1.lessonOrder-l2.lessonOrder)
            var folders = sortedLesson.map(function (subs) {
                i++;

                var currentDate = new Date();
                var scheduleDate = new Date(subs.schedule);
                var today = new Date(
                    Date.UTC(
                        currentDate.getUTCFullYear(),
                        currentDate.getUTCMonth(),
                        currentDate.getUTCDate()
                    )
                );
                var schDate = new Date(
                    Date.UTC(
                        scheduleDate.getUTCFullYear(),
                        scheduleDate.getUTCMonth(),
                        scheduleDate.getUTCDate()
                    )
                );
                var textData = "";
                if (currentDate > scheduleDate) {
                    textData = "Live";
                } else {
                    var months = parseInt(scheduleDate.getUTCMonth());
                    textData =
                        (scheduleDate.getUTCDate()+1) +
                        "-" +
                        monthNames[months] +
                        "-" +
                        scheduleDate.getUTCFullYear();
                }
                if (!subs.schedule) {
                    textData = "";
                }
                if (!subs.hideForMember) {
                    if (currentDate > scheduleDate || subs.schedule == "") {
                        return (
                            <tr key={subs._id}>
                                <td>
                                    <h3 className="table-title">{i}</h3>
                                </td>
                                <td>
                                    <h3 className="table-title">{subs.title}</h3>
                                </td>
                                <td>
                                    <div dangerouslySetInnerHTML={{ __html: subs.goal }}></div>
                                </td>
                                <td>{textData}</td>
                                <td>
                                    <Link className="btn btn-live" to={`/lesson/${subs._id}`}>
                                        View Lesson
                                    </Link>
                                </td>
                            </tr>
                        );
                    }
                    else if (currentDate < scheduleDate && types == "schedule") {
                        return (
                            <tr key={subs._id}>
                                <td>
                                    <h3 className="table-title">{i}</h3>
                                </td>
                                <td>
                                    <h3 className="table-title">{subs.title}</h3>
                                </td>
                                <td>
                                    <div dangerouslySetInnerHTML={{ __html: subs.goal }}></div>
                                </td>
                                <td>{textData}</td>
                                <td>
                                    <Link className="btn btn-live" to={`/lesson/${subs._id}`}>
                                        View Lesson
                                    </Link>
                                </td>
                            </tr>
                        );
                    }
                }
            });
        } else {
            var folders = "No records found.";
        }

        if (types === "live") {
            setLive(folders);
        }
        if (types === "draft") {
            setDraft(folders);
        }
        if (types === "schedule") {
            setSchedule(folders);
        }
    };

    const lessonDetails = (lessonId) => {
        getCourseLessonById(lessonId).then((data) => {
            if (data) {
                var files = data.result.validUser.lessonsFile[0]["fileUrl"];
                var fileName = files.substring(files.lastIndexOf("/") + 1, files.lastIndexOf("."));

                var fileExt = files.substring(files.lastIndexOf(".") + 1);

                var types = "";
                if (fileExt === "png" || fileExt === "jpeg" || fileExt === "jpg") {
                    types = "image";
                }
                if (fileExt === "mp4" || fileExt === "mov") {
                    types = "video";
                }
                if (fileExt === "mp3") {
                    types = "audio";
                }

                coursecommentlist(lessonId).then((commentData) => {
                    const resource = data.result.validUser.newResource.map(function (resource) {
                        var filename = resource.resourceUrl.split("/").pop();

                        return (
                            <div className="resourcesLists">
                                <a href={resource.resourceUrl} target="_blank">
                                    {filename}
                                </a>
                            </div>
                        );
                    });

                    setTitle(data.result.validUser.title);
                    setDescription(data.result.validUser.description);
                    setGoal(data.result.validUser.goal);
                    setFileName(fileName);
                    setTypes(types);
                    setFiles(files);
                    setCurrentLesson(lessonId);
                    setCurrentCourse(data.result.validUser.courseId._id);
                    setResource(resource);
                    openModal();
                });
            }
        });
    };

    const savevideocomment = async () => {
        if (comment) {
            setIsLoader(true);
            let user_id = localStorage.getItem("user_id");

            const commentData = {
                lessonId: currentLesson,
                courseId: currentCourse,
                subadminId: user_id,
                comment: comment,
            };
            await saveCoursecomment(commentData);
            coursecommentlist(currentLesson);
            setIsLoader(false);
            setComment("");
        }
    };

    const iteratecomments = (datas) => {
        let user_id = localStorage.getItem("user_id");
        const comment = datas.commentByUserId.map(function (vid) {
            var imgs = "";
            var key = "";
            if (vid.userId) {
                key = vid.userId;
            } else {
                key = vid.subadminId;
            }

            if (key.image === "") {
                imgs = placeholder;
            } else {
                imgs = key.image;
            }

            return (
                <div className="media" key={vid._id}>
                    <img src={imgs} className="user-img img-fluid mr-3" alt="User" />
                    <div className="media-body">
                        <h5 className="mt-0 messanger-name">
                            {key.firstName} {key.lastName}
                        </h5>
                        <p className="messanger-text">{vid.comment}</p>
                    </div>
                </div>
            );
        });

        if (datas.commentByUserId.length > 0) {
            setVideocomment(comment);
        } else {
            setVideocomment("No records found.");
        }
    };

    const coursecommentlist = (idd) => {
        coursecommentlist(idd).then((data) => {
            iteratecomments(data.result);
        });
    };

    const enterPressed = (event) => {
        var code = event.keyCode || event.which;
        if (code === 13) {
            savevideocomment();
        }
    };

    const onChange = (event) => {
        event.preventDefault();
        const { name, value } = event.target;
        setComment(value);
    };

    useEffect(() => {
        $(document).ready(function () {
            $(".audioPreviewModal").attr("id", "audioPreviewModal");
        });
    }, []);

    return (
        <section className="courses-list">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xl-12">
                        <ul className="nav nav-tabs" id="coursesTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link active"
                                    id="liveLessons-tab"
                                    data-toggle="tab"
                                    href="#liveLessons"
                                    role="tab"
                                    aria-controls="liveLessons"
                                    aria-selected="true"
                                >
                                    Lessons For Module
                                </a>
                            </li>
                        </ul>
                        <div className="tab-content" id="coursesTabContent">
                            <div
                                className="tab-pane fade show active"
                                id="liveLessons"
                                role="tabpanel"
                                aria-labelledby="liveLessons-tab"
                            >
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <tbody>{live}</tbody>
                                    </table>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="scheduledLessons"
                                role="tabpanel"
                                aria-labelledby="scheduledLessons-tab"
                            >
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <tbody>{schedule}</tbody>
                                    </table>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="draftLessons"
                                role="tabpanel"
                                aria-labelledby="draftLessons-tab"
                            >
                                <div className="table-responsive">
                                    <table className="table table-hover">
                                        <tbody>{draft}</tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                className="audioPreviewModal"
                show={isOpen}
                onHide={closeModal}
                backdrop="static"
            >
                <Modal.Header className="modal-header border-0 pb-0 align-items-center">
                    <h5 className="modal-title pl-0">Preview Lesson</h5>
                    <button
                        onClick={closeModal}
                        type="button"
                        className="close ml-5"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">
                            <img src={close} alt="Close" />
                        </span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center mb-2">
                        {types === "image" ? (
                            <img
                                src={files}
                                className="img-fluid mx-auto d-block mb-2"
                                height="180"
                                alt="Lesson Preview"
                            />
                        ) : (
                            ""
                        )}

                        {types === "video" ? (
                            <ReactPlayer
                                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                url={files}
                                controls={true}
                                playing={true}
                                width="100%"
                                height="353px"
                            />
                        ) : (
                            ""
                        )}

                        {types === "audio" ? (
                            <ReactPlayer
                                url={files}
                                controls={true}
                                playing={true}
                                width="100%"
                                height="50px"
                            />
                        ) : (
                            ""
                        )}
                    </div>
                    <h5 className="modal-title mr-0">{title}</h5>
                    <p className="text-break">{description}</p>
                    <h5 className="modal-title mr-0 mb-2">Resources</h5>
                    <div className="scroll">
                        <div className="resources mb-3">
                            {resource !== "" ? resource : "Resource not available."}
                        </div>
                    </div>
                    <div className="comments">
                        <div className="form-row mb-3 align-items-center">
                            <div className="col-10">
                                <input
                                    type="text"
                                    className="form-control leave-comment"
                                    placeholder="Leave a comment"
                                    name="comment"
                                    onChange={onChange}
                                    onKeyPress={enterPressed}
                                    value={comment}
                                />
                            </div>
                            <div className="col-2">
                                <img
                                    src={submit}
                                    className="img-fluid comment-img"
                                    width={56}
                                    height={38}
                                    onClick={savevideocomment}
                                    alt="Submit Comment"
                                />
                            </div>
                        </div>
                        <h4 className="title">Comments</h4>
                        <div className="messages-list">{videocomment}</div>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    );
};

export default ModuleLessons;