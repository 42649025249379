import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";

import doc from "../../../images/admin/doc.png";

export default function MediaMediaMedia(props) {
  const [containerData, setContainerData] = useState("");
  useEffect(() => {
    if (props.containers.length > 0 && props.containers[0]) {
      const containerData = getContentData(props.containers);
      setContainerData(containerData);
    }
  }, [props.containers]);

  const getContentData = (containers) => {
    return containers.map(function (subs, index) {
      var filename = subs?.media?.url?.substring(
        subs?.media?.url?.lastIndexOf("/") + 1
      );
      var ext = filename?.substr(filename?.lastIndexOf(".") + 1);
      var paths = "";
      var isDoc = false;
      if (ext == "pdf" || ext == "doc" || ext == "docx" || ext == "xlsx") {
        paths = doc;
        isDoc = true;
      } else {
        paths = subs?.media?.url;
      }
      if (ext == "mkv" || ext == "wmv" || ext == "webm" || ext == "mpeg" || ext == "avi" ||ext == "flv" ||ext == "mpg") {
        return (
          <div className="col-md-4 text-center">
            <p className="text">
              {subs.media.mediaTitle !== "undefined"
                ? subs.media.mediaTitle
                : ""}
            </p>
            <ReactPlayer
              config={{ file: { attributes: { controlsList: "nodownload" } } }}
              url={paths}
              controls={true}
              playing={false}
              type="video/mov"
              width="423"
              height="353"
            />
          </div>
        );
      } else if (ext === "mp3") {
        return (
          <div key={index} className="col-md-4 text-center">
            <p className="text">
              {subs.media.mediaTitle !== "undefined"
                ? subs.media.mediaTitle
                : ""}
            </p>
            <div className="audio-player-wrapper">
              <ReactAudioPlayer src={paths} controls={true} />
            </div>
          </div>
        );
      } else {
        return (
          <div key={index} className="col-md-4 text-center">
            <p className="text">
              {subs?.media?.mediaTitle !== "undefined"
                ? subs?.media?.mediaTitle
                : ""}
            </p>
            <img src={paths} className="img-fluid" />
            {isDoc ? (
              <p>
                <a target="_blank" href={subs.media.url}>
                  Download
                </a>
              </p>
            ) : (
              ""
            )}
          </div>
        );
      }
    });
  };

  return (
    <div className="template">
      <div className="row">{containerData}</div>
    </div>
  );
}
