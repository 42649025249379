import React, { useState } from "react";

import { updateAdminForgotPassword } from "../helper/service";
import bgImage from "../images/body-bg.svg";

export default function Resetpassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const submitForm = async (event) => {
    event.preventDefault();
    setIsLoader(true);
    setIsSuccess(false);

    const currentMemberId = window.location.pathname.split("/").pop();
    let data = {
      newPassword: newPassword,
      conPassword: confirmPassword,
      userId: currentMemberId,
    };

    await updateAdminForgotPassword(data)
      .then((data) => {
        alert("Password has been changed successfully. Now you can login");
        window.location.href = "/login";
      })
      .catch((error) => {
        if (error.response.data.body === "please enter correct Password ") {
          var msg = error.response.data.body
            .toLowerCase()
            .replace(/\b(\w)/g, (s) => s.toUpperCase());
          alert(msg);
        }

        if (error.response.data.body === "Please confirm the password") {
          alert("Password & Confirm Password should be same");
        }
      })
      .finally(() => {
        setIsLoader(false);
        setIsSuccess(true);
        setNewPassword("");
        setConfirmPassword("");
      });
  };

  document.body.style.backgroundImage = "url(" + bgImage + ")";

  return (
    <div className="wrapper">
      <section id="forgot" className="d-flex align-items-center">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="card">
                <div className="card-body">
                  <h2 className="forgot-title mt-0">Change Password</h2>
                  <form name="form" onSubmit={submitForm}>
                    <div className="form-group">
                      <label>New Password</label>
                      <input
                        required
                        type="password"
                        className="form-control"
                        id
                        placeholder="********"
                        name="newPassword"
                        onChange={(e) => setNewPassword(e.target.value)}
                        value={newPassword}
                      />
                    </div>
                    <div className="form-group mb-5">
                      <label>Repeat new Password</label>
                      <input
                        required
                        type="password"
                        className="form-control"
                        id
                        placeholder="********"
                        name="confirmPassword"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                      />
                    </div>
                    <div className="d-flex justify-content-between flex-wrap">
                      <button type="submit" className="btn btn-info">
                        Set new password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
