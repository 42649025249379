import React from "react";
import placeholder from "../../images/placeholder.jpg";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import defaultImg from "../../images/avatar.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { monthNames } from "../../helper/constants";

export default function EventComments({ comments }) {
  return (
    comments?.length > 0 && (
      <div className="event-comments">
        <h4 className="title">Comments</h4>
        <div className="comment-list">
          {comments.map(function (sub, key) {
            var fname = "";
            var lname = "";
            var img = placeholder;
            if (sub.subadminId) {
              fname = sub.subadminId.firstName;
              lname = sub.subadminId.lastName;
              if (sub.subadminId.company[0].logo && sub.subadminId.company.length > 0) {
                img = sub.subadminId.company[0].logo;
              }
              else if(sub.subadminId.image){
                img=sub.subadminId.image
              }
               else {
                img = defaultImg;
              }
            } else {
              fname = sub.userId.firstName;
              lname = sub.userId.lastName;
              if (!sub.userId.image) {
                img = defaultImg;
              } else {
                img = sub.userId.image;
              }
            }
            var d1 = new Date(sub.createdAt);

            var months1 = parseInt(d1.getUTCMonth());
            var schedule1 =
              d1.getUTCDate() +
              "-" +
              monthNames[months1] +
              "-" +
              d1.getUTCFullYear();
            var times1 = d1.getUTCHours() + ":" + d1.getUTCMinutes();

            var times1 =
              (d1.getHours() < 10 ? "0" : "") +
              d1.getHours() +
              ":" +
              (d1.getMinutes() < 10 ? "0" : "") +
              d1.getMinutes();

            return (
              <div className="media" key={key}>
                {!img ? (
                  <FontAwesomeIcon
                    icon={faUser}
                    className="user-img img-fluid mr-2"
                  />
                ) : (
                  <img src={img} className="user-img img-fluid mr-2" />
                )}
                <div className="media-body">
                  <h5 className="mt-0 comment-name">
                    {fname} {lname}
                  </h5>
                  <p className="comment-text">{sub.comment}</p>
                  <span className="date-time">
                    {schedule1} at {times1}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
}
